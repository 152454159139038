<template>
  <PageLoading />
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import PageLoading from "@/components/PageLoading";
export default {
  name: "Auth",
  components: {
    PageLoading,
  },

  async mounted() {
    const { token, tokenReseller, domain, key } = this.$route.query;

    this.setCompany(null);

    const isAuhtenticated = await this.authentication(token);

    if (isAuhtenticated) {
      this.syncUsersAsync();
      this.setTokenReseller(tokenReseller);

      localStorage.setItem("show_onboarding", this.showOnboardingUser);

      if (!this.appInstalled && !this.surveyAnswered) {
        this.$router.push({ name: "OnboardingSurvey" });
        return;
      }

      if (this.nextPage) {
        localStorage.removeItem("nextPage");
        this.$router.push({ name: this.nextPage });
      } else if (domain && key) {
        const clientPartnerUrl = `/partner/customer?domain=${domain}&key=${key}`;
        this.$router.push(clientPartnerUrl);
      } else {
        this.$router.push({ name: "Home" });
      }
    } else {
      this.logout();
    }
  },

  methods: {
    ...mapActions(["authentication", "syncUsersAsync", "logout"]),
    ...mapMutations(["setTokenReseller", "setCompany"]),
  },

  computed: {
    ...mapGetters([
      "showOnboardingUser",
      "nextPage",
      "currentUser",
      "surveyAnswered",
      "appInstalled",
    ]),
  },
};
</script>

<style scoped>
.auth {
  background-color: #f5f5f5 !important;
}
</style>
